import * as React from "react"
import type { HeadFC } from "gatsby"
import { useCookies} from "react-cookie";

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 600,
}
const headingAccentStyles = {
  color: "#663399",
}

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}

const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  display: `inline-block`,
  marginBottom: 24,
  marginRight: 12,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}



const IndexPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['CF_Authorization']);

  let links = [
  {
    text: "Weather",
    url: "/forecast?id=1",
    description:
      "Weather on the 2s",
    color: "#1099A8",
  }
]

  let authN = {
      "username": "anonymous",
      "givenName": "",
      "surName": ""
  }

  try {
    authN = JSON.parse(atob(cookies["CF_Authorization"].split(".")[1]))["custom"]
  } catch {
    console.warn("Could not parse authentication data")
  }

  const ADULTS = ['drew', 'lindsay']
  const NEWS_USERS = ['drew', 'elijah', 'lindsay']

  if (ADULTS.indexOf(authN.username) >= 0) {
    links.push({
    text: "Court Calendar",
    url: "/nccourt?id=1",
    description:
      "NC Court Calendar",
    color: "#1099A8",
  })
  }


  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>
        Hello {authN.givenName} {authN.surName}!
        <br />
        <span style={headingAccentStyles}>— You are browsing securely! 🎉🎉🎉</span>
      </h1>
      <ul style={listStyles}>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}&user=${authN.username}`}
              >
                {link.text}
              </a>
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>

    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
